// src/components/ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import axios from "axios";
import toast from "react-hot-toast";

export const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { currentUser, loading: authLoading } = useAuth();
  const [authorized, setAuthorized] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAccess = async () => {
      // Wait for AuthContext to finish loading
      if (authLoading) return;

      if (!currentUser) {
        setAuthorized(false);
        return;
      }

      if (!adminOnly) {
        setAuthorized(true);
        return;
      }

      try {
        const idToken = await currentUser.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/profile`,
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );

        const { role, status } = response.data;

        if (
          adminOnly &&
          (role === "admin" || role === "super-admin") &&
          status === "active"
        ) {
          setAuthorized(true);
        } else if (status !== "active") {
          toast.error("Your account is pending approval.");
          setAuthorized(false);
        } else {
          toast.error("Access restricted to administrators.");
          setAuthorized(false);
        }
      } catch (error) {
        toast.error("Failed to verify user status.");
        console.error("ProtectedRoute verification error:", error);
        setAuthorized(false);
      }
    };

    verifyAccess();
    // eslint-disable-next-line
  }, [currentUser, adminOnly, authLoading]);

  useEffect(() => {
    if (authorized === false && !authLoading) {
      navigate("/");
    }
  }, [authorized, authLoading, navigate]);

  if (authLoading || authorized === null) {
    return <p>Loading...</p>; // Show loading until resolved
  }

  return authorized ? children : null;
};
