import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "firebase/auth";
import toast from "react-hot-toast";

function Settings() {
  const { logout, userDetails, currentUser, updateUserDetails, resetPassword } =
    useAuth();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: userDetails.email,
    name: userDetails.name,
    phone: userDetails.phone,
    address: userDetails.address,
    city: userDetails.city,
    state: userDetails.state,
    zip: userDetails.zip,
  });

  useEffect(() => {
    setFormData({
      email: userDetails.email,
      name: userDetails.name,
      phone: userDetails.phone,
      address: userDetails.address,
      city: userDetails.city,
      state: userDetails.state,
      zip: userDetails.zip,
    });
  }, [userDetails]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleResetPassword = () => {
    resetPassword(currentUser.email).catch((error) => {
      console.error("Reset password error:", error);
      toast.error("Failed to send reset password email.");
    });
  };

  const US_STATES = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // First, try updating the Firebase profile
      await updateProfile(currentUser, {
        displayName: formData.name,
      });
      const response = await fetch(`${backendUrl}/api/user/update-profile`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser.accessToken}`, // Ensure you are using the correct token
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Profile updated successfully!");
        // Optionally update local user details if needed
        updateUserDetails(formData);
      } else {
        throw new Error(data.message || "Failed to update profile");
      }
    } catch (error) {
      console.error("Failed to update profile:", error.message);
      toast.error(error.message || "Failed to update profile.");
    }
  };

  return (
    <div className="settings">
      <h2>ACCOUNT DETAILS</h2>
      <form onSubmit={handleSubmit}>
        <label>Email: </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          disabled
        />
        <br></br>
        <br></br>
        <label>Name: </label>
        <input
          type="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <label>Phone Number: </label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <label>Delivery Address: </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <label>City: </label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <label>State: </label>
        <select
          id="state"
          name="state"
          value={formData.state}
          onChange={handleChange}
          required
        >
          <option value="">Select a State</option>
          {US_STATES.map((state) => (
            <option key={state.code} value={state.code}>
              {state.name}
            </option>
          ))}
        </select>
        <br></br>
        <br></br>
        <label>ZIP: </label>
        <input
          type="text"
          name="zip"
          value={formData.zip}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <button type="submit">Update Profile</button>
      </form>
      <button onClick={handleLogout}>Logout</button>
      <button onClick={handleResetPassword}>Forgot Password</button>
    </div>
  );
}

export default Settings;
