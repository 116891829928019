import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

function UserRequest() {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/request`,
        formData
      );
      toast.success(response.data.message);
      setFormData({
        name: "",
        company: "",
        email: "",
        phone: "",
      });
    } catch (error) {
      console.error("Submission failed", error);
      toast.error(
        error.response ? error.response.data : "Error submitting request"
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="company"
        placeholder="Company"
        value={formData.company}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="tel"
        name="phone"
        placeholder="Phone Number"
        value={formData.phone}
        onChange={handleChange}
        required
      />
      <button type="submit">SUBMIT</button>
    </form>
  );
}

export default UserRequest;
