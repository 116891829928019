// OrderDetails.js
import React from "react";

const OrderDetails = ({ orderInfo }) => {
  return (
    <div>
      <h2>Order Summary</h2>
      <p>
        <strong>Name:</strong> {orderInfo.name}
      </p>
      <p>
        <strong>Address:</strong> {orderInfo.address}
      </p>
      <p>
        <strong>Phone:</strong> {orderInfo.phone}
      </p>
      <p>
        <strong>Email:</strong> {orderInfo.email}
      </p>
      <p>
        <strong>Total Rental Price for Discounted Priorum Items Only:</strong> $
        {orderInfo.totalRentalPrice.toFixed(2)}
      </p>
      <p>
        <strong>Total Savings:</strong> ${orderInfo.totalSavings.toFixed(2)}
      </p>
    </div>
  );
};

export default OrderDetails;
