import { createSlice } from "@reduxjs/toolkit";

export const dateSlice = createSlice({
  name: "eventDate",
  initialState: {
    date: null,
  },
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    clearDate: (state) => {
      state.date = null;
    },
  },
});

export const { setDate, clearDate } = dateSlice.actions;
export default dateSlice.reducer;
