import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Progress from "@radix-ui/react-progress";
import ItemBox from "../../components/itemBox/itemBox";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import "./itemListPage.css";

const ItemListPage = () => {
  const { supercategoryId, categoryId } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [favorites, setFavorites] = useState([]);
  const [showNonDiscounted, setShowNonDiscounted] = useState(false); // Toggle for non-discounted items
  const eventDate = useSelector((state) => state.eventDate);
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.items);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const [progress, setProgress] = useState(0); // Add state for progress

  const fetchFavorites = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in the Authorization header
          },
        }
      );
      const data = await response.json();
      setFavorites(data.favorites);
    } catch (err) {
      console.error("Failed to fetch favorites:", err);
    }
  };

  // Fetch user favorites from the backend
  useEffect(() => {
    fetchFavorites();
  }, []);

  useEffect(() => {
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
      return;
    }

    setLoading(true);
    setProgress(0);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // Updated URL construction logic to correctly handle supercategoryId and categoryId
    let urlParams = new URLSearchParams();
    if (eventDate.date) {
      const eventDateString = eventDate.date.split("T")[0];
      urlParams.append("eventDate", eventDateString);
    }
    if (supercategoryId) {
      urlParams.append("superCategoryId", supercategoryId);
    }
    if (categoryId) {
      urlParams.append("categoryId", categoryId);
    }
    let url = `${backendUrl}/api/items?${urlParams.toString()}`;

    const progressInterval = setInterval(() => {
      setProgress((prev) => Math.min(prev + 5, 95)); // Increment progress up to 90%
    }, 300); // Simulate progress every 200ms

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setItems(data.data);
        setFilteredItems(data.data);
        setProgress(100); // Set progress to 100% when done
        setLoading(false);
        clearInterval(progressInterval); // Clear the progress interval
      })
      .catch((error) => {
        console.error("Failed to fetch items:", error);
        setLoading(false);
        setError("Failed to fetch items.");
        clearInterval(progressInterval); // Clear the progress interval
      });
  }, [supercategoryId, categoryId, eventDate, navigate]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = items.filter((item) =>
      item.ItemDescription.toLowerCase().includes(query)
    );
    setFilteredItems(filtered);
  };

  const toggleFavorite = async (itemId) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const isFavorited = favorites.includes(itemId.toString());
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          method: isFavorited ? "DELETE" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ itemId }),
        }
      );

      if (response.ok) {
        // Fetch the updated favorites after successfully adding/removing
        await fetchFavorites();
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  const discountedItems = filteredItems.filter(
    (item) => item.DiscountsApplied?.length > 0
  );

  const nonDiscountedItems = filteredItems.filter(
    (item) => item.DiscountsApplied?.length === 0
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack items vertically
          alignItems: "center", // Center items horizontally
          width: "100%", // Full-width container
          marginTop: "20px", // Add some margin for vertical spacing
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          Getting Item Availability. Please Hold...
        </div>
        <Progress.Root
          className="progress-root"
          style={{
            position: "relative",
            width: "50%",
            height: 20,
            background: "#eee",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          value={progress}
        >
          <Progress.Indicator
            className="progress-indicator"
            style={{
              background: "linear-gradient(90deg, #4caf50, #8bc34a)",
              height: "100%",
              width: `${progress}%`,
              transition: "width 0.2s",
            }}
          />
        </Progress.Root>
      </div>
    );
  }

  if (error)
    return (
      <div>
        <p style={{ color: "red" }}>ERROR: </p>
        <p>Network Error</p>
        <button onClick={() => navigate("/date")}>BACK TO CALENDAR</button>
      </div>
    );

  return (
    <div className="item-list-page">
      {name && <h1>{decodeURIComponent(name)}</h1>}
      <p>
        *Note: If higher quantities at regular pricing are needed enter on the
        cart page.
      </p>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search items..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="button-container">
        <button onClick={() => navigate("/home")}>ADD MORE ITEMS</button>
      </div>
      <div className="items-container">
        {discountedItems.length > 0 ? (
          discountedItems.map((item) => (
            <ItemBox
              key={item.ItemID}
              item={item}
              name={name}
              discountQuantityInCart={
                cart.find((cartItem) => cartItem.id === item.ItemID)
                  ?.quantity || 0
              }
              regularQuantityInCart={
                cart.find((cartItem) => cartItem.id === item.ItemID)
                  ?.regularQuantity || 0
              }
              isFavorited={favorites.includes(item.ItemID.toString())}
              toggleFavorite={toggleFavorite}
            />
          ))
        ) : (
          <p>No discounted items.</p>
        )}
      </div>
      {(showNonDiscounted || discountedItems.length === 0) && (
        <div className="items-container non-discounted-items">
          {nonDiscountedItems.length > 0 ? (
            nonDiscountedItems.map((item) => (
              <ItemBox
                key={item.ItemID}
                item={item}
                name={name}
                discountQuantityInCart={
                  cart.find((cartItem) => cartItem.id === item.ItemID)
                    ?.quantity || 0
                }
                regularQuantityInCart={
                  cart.find((cartItem) => cartItem.id === item.ItemID)
                    ?.regularQuantity || 0
                }
                isFavorited={favorites.includes(item.ItemID.toString())}
                toggleFavorite={toggleFavorite}
              />
            ))
          ) : (
            <p>No non-discounted items.</p>
          )}
        </div>
      )}
      {discountedItems.length > 0 && (
        <button onClick={() => setShowNonDiscounted(!showNonDiscounted)}>
          {showNonDiscounted
            ? "Hide Non-Discounted Items"
            : "Show Non-Discounted Items"}
        </button>
      )}
    </div>
  );
};

export default ItemListPage;
