// src/api/ordersApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_URL }),
  endpoints: (builder) => ({
    // Fetch orders
    fetchOrders: builder.query({
      query: () => "/api/orders/list",
    }),
    // Delete order
    deleteOrder: builder.mutation({
      query: ({ orderId }) => ({
        url: `/api/orders/${orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
  tagTypes: ["Orders"],
});

export const { useFetchOrdersQuery, useDeleteOrderMutation } = ordersApi;
