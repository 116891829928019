import React, { useState } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import "./discountModal.css";

const DiscountModal = ({
  type,
  referenceId,
  onClose,
  name,
  onSubmitSuccess,
  selectedDiscount,
}) => {
  const [percentageOfAvailability, setPercentageOfAvailability] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [startDate, setStartDate] = useState(
    selectedDiscount?.startDate
      ? new Date(selectedDiscount.startDate).toISOString().slice(0, 10)
      : ""
  );
  const [endDate, setEndDate] = useState(
    selectedDiscount?.endDate
      ? new Date(selectedDiscount.endDate).toISOString().slice(0, 10)
      : ""
  );
  const [isSeasonal, setIsSeasonal] = useState(!!selectedDiscount);
  const [isNoDiscount, setIsNoDiscount] = useState(
    selectedDiscount?.noDiscount || false
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const discountData = {
      type: isSeasonal ? "seasonal" : type,
      referenceId,
      tiers: isNoDiscount
        ? []
        : [
            {
              percentageOfAvailability: parseFloat(percentageOfAvailability),
              discountPercentage: parseFloat(discountPercentage),
            },
          ],
      ...(isSeasonal && { startDate, endDate }),
      noDiscount: isNoDiscount,
    };

    if (type === "category" || type === "superCategory") {
      discountData.referenceId = referenceId;
      discountData.onModel = type.charAt(0).toUpperCase() + type.slice(1);
    } else if (type === "item") {
      discountData.itemRef = referenceId;
    }

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/discounts`;
      await axios.post(url, discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert("Discount created successfully");
      onSubmitSuccess();
      onClose();
    } catch (error) {
      console.error(error);
      alert(
        `Error creating discount: ${error.response?.data || error.message}`
      );
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>
          Add {type.charAt(0).toUpperCase() + type.slice(1)} Discount - {name}
        </h2>
        <form onSubmit={handleSubmit}>
          {type === "item" && (
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isSeasonal}
                  onChange={(e) => setIsSeasonal(e.target.checked)}
                  disabled={!!selectedDiscount} // Disable for existing seasonal discounts
                />
                Seasonal Discount
              </label>
            </div>
          )}
          {isSeasonal && (
            <>
              <label>Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
              <label>End Date:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={isNoDiscount}
                    onChange={(e) => setIsNoDiscount(e.target.checked)}
                  />
                  No Discount
                </label>
              </div>
            </>
          )}
          {/* Show discount tiers only if "No Discount" is not selected */}
          {!isNoDiscount && (
            <>
              <div>
                <label>Percentage Of Availability:</label>
                <input
                  type="number"
                  value={percentageOfAvailability}
                  onChange={(e) => setPercentageOfAvailability(e.target.value)}
                  min="0"
                  max="100"
                  required
                />
              </div>
              <div>
                <label>Discount Percentage:</label>
                <input
                  type="number"
                  value={discountPercentage}
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                  min="0"
                  max="100"
                  required
                />
              </div>
            </>
          )}
          <div className="modal-actions">
            <button type="submit">Create Discount</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DiscountModal;
