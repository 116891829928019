import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { NavLink } from "react-router-dom";

function CheckStatus({ type }) {
  const [email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/${type}/status?email=${email}`;
    try {
      const response = await axios.get(url);
      setUserInfo(response.data);
      toast.success("Status fetched successfully");
      setEmail("");
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "Failed to check status"
      );
      setUserInfo(null);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <button type="submit">CHECK</button>
      </form>
      {userInfo && (
        <div
          style={{
            padding: "20px",
            border: "1px solid #ccc",
            marginTop: "20px",
          }}
        >
          <p>Name: {userInfo.name}</p>
          {userInfo.company && <p>Company: {userInfo.company}</p>}
          <p>Email: {userInfo.email}</p>
          {userInfo.phone && <p>Phone: {userInfo.phone}</p>}
          <p>
            Status: {userInfo.status}{" "}
            {userInfo.status === "active" ? "✅" : "⏳"}
          </p>
          {userInfo.status === "active" && (
            <div>
              <p>
                You have been accepted! <NavLink to="/auth">Sign Up</NavLink>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CheckStatus;
