import React, { useState } from "react";
import AdminOrders from "../components/admin/adminOrders";
import LogList from "../components/admin/logList";
import AcceptAccounts from "../components/admin/acceptAccounts";
import { useAuth } from "../context/authContext";
import SuperCategoryList from "../components/admin/superCategoryList";
import DiscountModal from "../components/admin/discountModal";

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState("orders"); // New state to control active tab
  const { currentUser, logout, userDetails } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close

  // Function to open modal
  const openDiscountModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeDiscountModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <p>Welcome {currentUser?.email}!</p>
      <div>
        {userDetails.role === "super-admin" && (
          <button
            onClick={() => setActiveTab("discounts")}
            style={{ color: activeTab === "discounts" ? "white" : "black" }}
          >
            Discounts
          </button>
        )}
        <button
          onClick={() => setActiveTab("orders")}
          style={{ color: activeTab === "orders" ? "white" : "black" }}
        >
          Orders
        </button>
        <button
          onClick={() => setActiveTab("logs")}
          style={{ color: activeTab === "logs" ? "white" : "black" }}
        >
          Logs
        </button>
        <button
          onClick={() => setActiveTab("accept")}
          style={{ color: activeTab === "accept" ? "white" : "black" }}
        >
          Accept Accounts
        </button>
        <button onClick={logout}>Log Out</button>
      </div>
      {activeTab === "discounts" && userDetails.role === "super-admin" && (
        <>
          <h1>Manage Discounts</h1>
          {/* Button to open modal */}
          <button
            className="add-discount-button"
            onClick={openDiscountModal}
            title="Add Blanket Discount"
          >
            Add Blanket Discount
          </button>{" "}
          <SuperCategoryList />
        </>
      )}
      {activeTab === "orders" && (
        <>
          <h1>Manage Orders</h1>
          <AdminOrders />
        </>
      )}
      {activeTab === "logs" && (
        <>
          <h1>Logs</h1>
          <LogList />
        </>
      )}
      {activeTab === "accept" && <AcceptAccounts />}
      {isModalOpen && (
        <DiscountModal
          type="blanket" // Pass "blanket" type for blanket discounts
          onClose={closeDiscountModal} // Close modal on completion
          onSubmitSuccess={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default AdminPage;
