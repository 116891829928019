export const quantityRules = {
  supercategories: {
    "66242a33e12ce3692df1f5dc": 10, // Flatware supercategory ID with default quantity
    "65f617e5d6aa089047637b6b": 10, //Flatware supercategory ID for prod
  },
  items: {
    // Madison Glassware
    5057: 36, // Madison Champagne Flute
    9932: 25, // Madison Port Wine
    5059: 25, // Madison Red Wine
    5060: 25, // Madison Water Goblet
    5058: 25, // Madison White Wine
    // Traditional Glassware
    5061: 36, // Champagne Flute
    5067: 25, // Water Goblet
    5065: 25, // Wine 11oz
    5064: 36, // Wine 8oz
    // Barware
    11915: 25, // British Pint Glass
    5063: 25, // Champagne Saucer 4oz
    21027: 25, // Champagne Saucer 5oz
    5076: 20, // Coffee Mug
    5081: 16, // Cosmo 6oz
    11916: 25, // Craft Beer Pilsner Glass
    5072: 25, // Double Old Fashion 10.5oz
    5070: 36, // Hi-Ball 8oz
    5083: 9, // Margarita
    5080: 16, // Martini 6oz
    5068: 25, // Pilsner 12oz
    5069: 25, // Pint 16oz
    5075: 36, // Sherry 4oz
    5073: 49, // Shot Glass 1oz
    5071: 36, // Zombie 11oz
    // Specialty Glassware
    8196: 25, // Cancan Footed Beverage Glass Aqua 12oz
    8596: 25, // Confetti Tumbler 12oz
    8643: 20, // Copper Mug
    21399: 36, // Demitasse Cup 3oz
    5077: 36, // Demitasse Mug 3oz
    5079: 25, // Irish Coffee Mug 4oz
    11914: 25, // Irish Coffee Mug 8oz
    7984: 25, // Mason Jar 16oz
    5082: 20, // Punch Cup 6oz
    20785: 20, // Smoke Goblet
    20957: 20, // Antique Gray Depression Glass Goblet
    20955: 20, // Green Depression Glass Goblet
    // Stemless Glassware
    7828: 25, // Stemless Wine 11.75 oz
    10198: 36, // Stemless Flute 8.5oz
    // Stolzle Crystal Glassware
    11456: 36, // Stolzle Champagne Flute 5.25oz
    8258: 25, // Stolzle Double Old Fashioned 9oz
    8259: 36, // Stolzle Hi-Ball 12oz
    8257: 25, // Stolzle Old Fashioned 11oz
    11455: 20, // Stolzle Red Wine/Cabernet 22.5 oz
    11453: 16, // Stolzle Water/Burgundy 27.25oz
    11454: 25, // Stolzle White Wine/Chardonnay 12.75oz
    10229: 5, // White Rim Dinner Plate 10.75"
    11220: 5, // White Rim Salad Plate 8"
    11221: 5, // White Rim Bread and Butter Plate 6.5"
    11222: 16, // White Rim Coffee Cup 8oz
    21415: 5, // Castle White Dinner Plate 10.75"
    21417: 5, // Castle White Salad Plate 8.5"
    21416: 5, // Castle White Bread & Butter Plate 6.5"
    1059: 10, // White Coupe Dinner Plate 10"
    1060: 10, // White Coupe Salad Plate 7.5"
    1061: 5, // White Coupe Bread and Butter Plate 6"
    1062: 10, // White Hostess Plate 9"
    5841: 20, // White Coffee Mug
    1065: 16, // White Demitasse Cup 2.5oz
    1066: 10, // White Demitasse Saucer
    1067: 5, // White Soup or Cereal Bowl 6"
    5842: 5, // White Soup Plate
    1068: 16, // White Bouillon Cup
    1069: 10, // White Fruit and Sauce Dish 5"
    1070: 5, // White Round China Charger
    1063: 25, // White Coffee Cup Strawberry
    1081: 5, // White Triangular Dinner Plate 12"
    1082: 5, // White Triangular Salad Plate 10"
    1076: 5, // White Square Plate 4"
    1077: 5, // White Square Plate 6"
    1078: 5, // White Square Plate 8"
    1079: 5, // White Square Plate 10"
    5834: 5, // White Square Bowl 6"
    1071: 5, // Shell Dinner Plate
    1072: 5, // Shell Salad Plate
    1073: 5, // Shell Cereal Bowl
    1074: 20, // Shell Coffee Cup
    1054: 10, // Silver Rim Dinner Plate
    1055: 5, // Silver Rim Salad Plate
    1056: 5, // Silver Rim Bread and Butter Plate
    1057: 20, // Silver Rim Coffee Cup
    1058: 20, // Silver Rim Saucer
    11442: 5, // White w/ Single Gold Band Dinner Plate
    11452: 5, // White w/ Single Gold Band Luncheon Plate
    11441: 5, // White w/ Single Gold Band Salad Plate
    11443: 5, // White w/ Single Gold Band B&B Plate
    11446: 5, // White w/ Single Gold Band Soup Plate
    11444: 20, // White w/ Single Gold Band Coffee Cup
    1041: 5, // Elegance Dinner Plate
    1042: 5, // Elegance Salad Plate
    1043: 10, // Elegance Butter Plate
    1047: 16, // Elegance Demitasse Cup
    1050: 10, // Elegance Soup Plate
    9944: 10, // Homespun Linen Dinner Plate 10.75"
    9945: 10, // Homespun Linen Salad Plate 8"
    10251: 10, // Homespun Linen B&B Plate 6.25"
    10915: 16, // Homespun Linen Coffee Mug
    9943: 10, // Homespun Smoke Dinner Plate 10.75"
    9942: 10, // Homespun Smoke Salad Plate 8"
    10626: 10, // Homespun Smoke B&B Plate 6.25"
    8186: 10, // Jasmine Leaf Pattern B&B Plate 6.25"
    8187: 16, // Jasmine Leaf Pattern Coffee Cup 8oz
    8184: 10, // Jasmine Leaf Pattern Dinner Plate 10.5"
    8188: 16, // Jasmine Leaf Pattern Saucer 6.25"
    8185: 10, // Jasmine Leaf Pattern Salad Plate 8"
    8154: 20, // Marais Gold Coffee Cup 9oz
    8152: 10, // Marais Gold Dinner Plate 10.75"
    8153: 10, // Marais Gold Salad Plate 8"
    8163: 10, // Noir Black Stoneware Dinner Plate 11.25"
    8606: 10, // Noir Black Stoneware B&B Plate 6.5"
    8164: 10, // Noir Black Stoneware Salad Plate 8.25"
    8165: 16, // Noir Black Stoneware Coffee Mug
    8251: 5, // Floral Glaze Earthenware Dinner Plate
    8252: 5, // Floral Glaze Earthenware Salad Plate
    8254: 5, // Floral Glaze Earthenware Soup Bowl
    8255: 16, // Floral Glaze Earthenware Mug 16 oz
  },
};
