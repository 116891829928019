// RequestInstructions.jsx
import React from "react";

function RequestInstructions() {
  return (
    <div
      style={{
        padding: "20px",
        margin: "20px",
      }}
    >
      <h2 style={{ textAlign: "center", color: "#0056b3" }}>
        SIGN UP INSTRUCTIONS
      </h2>
      <ol
        style={{
          lineHeight: "1.6",
          fontSize: "20px",
          padding: "0 20px",
          listStylePosition: "inside",
          textAlign: "center",
        }}
      >
        <li>Request Account.</li>
        <li>Check Your Status For Approval (May Take Up to 48-Hours).</li>
        <li>Once Approved, Create Sign-In Email AND Password.</li>
        <li>Enjoy Your Savings!</li>
      </ol>
    </div>
  );
}

export default RequestInstructions;
