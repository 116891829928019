import React from "react";
import { useNavigate } from "react-router-dom";
import "./quantityExceededModal.css";

const QuantityExceededModal = ({ isOpen, onClose, catLink }) => {
  const navigate = useNavigate();

  if (!isOpen) return null; // Don't render if modal is closed

  const handleRedirect = () => {
    navigate(catLink); // Navigate to the category page
    onClose(); // Close the modal
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>No more quantity available on this date.</h2>
        <p>
          You have reached the maximum available quantity for this item. You can
          check out additional options from this category.
        </p>
        <button className="btn-redirect" onClick={handleRedirect}>
          View Additional Options
        </button>
        <button className="btn-qt-cancel" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default QuantityExceededModal;
