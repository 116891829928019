import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const updateCartData = createAsyncThunk(
  "cart/updateCartData",
  async (_, { getState, dispatch }) => {
    const { items } = getState().cart;
    const eventDate = getState().eventDate?.date; // Access eventDate from Redux state

    const updatedItems = await Promise.all(
      items.map(async (item) => {
        // Build the URL with eventDate if it exists
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        let url = `${backendUrl}/api/items/${item.id}`;
        if (eventDate) {
          const eventDateString = eventDate.split("T")[0]; // Format eventDate as necessary
          url += `?eventDate=${eventDateString}`;
        }

        // Fetch item details with eventDate
        const response = await fetch(url);
        const data = await response.json();

        return {
          ...item,
          itemDetails: data,
          updatedDiscountPercentage:
            data.DiscountsApplied?.[0]?.discountPercentage || 0,
        };
      })
    );

    const itemsWithIssues = [];
    const itemsWithDiscountChanges = [];

    updatedItems.forEach((updatedItem, index) => {
      const currentItem = items[index];

      if (
        currentItem.quantity + (currentItem.regularQuantity || 0) >
          updatedItem.itemDetails.AvailableQuantity ||
        currentItem.quantity > updatedItem.DiscountsApplied?.[0]?.quantity
      ) {
        itemsWithIssues.push(updatedItem);
      }

      if (
        updatedItem.updatedDiscountPercentage !==
        currentItem.itemDetails.DiscountsApplied?.[0]?.discountPercentage
      ) {
        itemsWithDiscountChanges.push(updatedItem);
      }
    });

    if (itemsWithIssues.length === 0 && itemsWithDiscountChanges.length === 0) {
      toast.success("No updates for items in cart."); // Show toast for no updates
    } else {
      dispatch(
        showCartUpdateModal({
          itemsWithIssues,
          itemsWithDiscountChanges,
        })
      );
    }

    return updatedItems;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    additionalQuantities: {},
    mandatoryQuantities: {},
    notes: "",
    modalData: {
      itemsWithIssues: [],
      itemsWithDiscountChanges: [],
      isOpen: false,
    },
  },
  reducers: {
    addToCart: (state, action) => {
      const existingIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.items[existingIndex].quantity += action.payload.quantity;
        state.items[existingIndex].regularQuantity +=
          action.payload.regularQuantity;
      } else {
        state.items.push(action.payload);
        // Initialize additional quantity when adding a new item
        state.additionalQuantities[action.payload.id] = 0;
        state.mandatoryQuantities[action.payload.id] =
          action.payload.mandatoryQuantities || 0;
      }
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
      state.notes = "";
    },
    updateNotes: (state, action) => {
      state.notes = action.payload; // Add this to handle updating notes
    },
    updateAdditionalQuantities: (state, action) => {
      const { itemId, quantity } = action.payload;
      state.additionalQuantities[itemId] = quantity;
    },
    showCartUpdateModal: (state, action) => {
      state.modalData = { ...action.payload, isOpen: true };
    },
    hideCartUpdateModal: (state) => {
      state.modalData.isOpen = false;
    },
    updateModalIssues: (state, action) => {
      state.modalData.itemsWithIssues = state.modalData.itemsWithIssues.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCartData.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  updateNotes,
  updateAdditionalQuantities,
  showCartUpdateModal,
  hideCartUpdateModal,
  updateModalIssues,
} = cartSlice.actions;

export default cartSlice.reducer;
