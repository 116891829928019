import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AdminSignUp from "../../components/Auth/adminSignUp";
import AdminLogin from "../../components/Auth/adminLogIn";
import UserLogin from "../../components/Auth/UserLogin";
import UserSignUp from "../../components/Auth/UserSignUp";
import "./AuthPage.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AuthPage() {
  const [activeTab, setActiveTab] = useState("user");
  const [authMode, setAuthMode] = useState("signup");
  const query = useQuery();

  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/LoginPage.jpg`;

  useEffect(() => {
    const mode = query.get("mode");
    if (mode === "login") {
      setAuthMode("login");
    }
  }, [query]);

  // Toggle the auth mode between 'login' and 'signup'
  const toggleAuthMode = () => {
    setAuthMode((prevMode) => (prevMode === "signup" ? "login" : "signup"));
  };

  return (
    <div>
      <div>
        <button
          onClick={() => setActiveTab("user")}
          disabled={activeTab === "user"}
          style={{
            color: activeTab === "user" ? "white" : "black",
            cursor: activeTab === "user" ? "default" : "pointer",
            pointerEvents: activeTab === "user" ? "none" : "auto",
          }}
        >
          Customer
        </button>
        <button
          onClick={() => setActiveTab("admin")}
          disabled={activeTab === "admin"}
          style={{
            color: activeTab === "admin" ? "white" : "black",
            cursor: activeTab === "admin" ? "default" : "pointer",
            pointerEvents: activeTab === "admin" ? "none" : "auto",
          }}
        >
          Priorum Staff
        </button>
      </div>

      {activeTab === "user" && (
        <>{authMode === "signup" ? <UserSignUp /> : <UserLogin />}</>
      )}
      {activeTab === "admin" && (
        <>{authMode === "signup" ? <AdminSignUp /> : <AdminLogin />}</>
      )}

      <div style={{ marginTop: "20px" }}>
        {authMode === "signup" ? (
          <button onClick={toggleAuthMode}>
            Already have an account? Log In!
          </button>
        ) : (
          <button onClick={toggleAuthMode}>Create an Account</button>
        )}
      </div>

      <img src={imageUrl} alt="SignUp" className="auth-page-image" />
    </div>
  );
}

export default AuthPage;
