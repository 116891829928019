import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { eventsApi } from "../api/eventsApi";
import { ordersApi } from "../api/ordersApi";
import cartReducer from "./cartSlice";
import dateSliceReducer from "./dateSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

// Config for cart reducer
const cartPersistConfig = {
  key: "cart",
  storage,
};

// Config for eventDate reducer
const datePersistConfig = {
  key: "eventDate",
  storage,
};

// Wrap the reducers with persistReducer
const persistedCartReducer = persistReducer(cartPersistConfig, cartReducer);
const persistedDateReducer = persistReducer(
  datePersistConfig,
  dateSliceReducer
);

export const store = configureStore({
  reducer: {
    // The API slice reducer (not using events rn)
    [eventsApi.reducerPath]: eventsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    // The cart reducer
    // cart: cartReducer,
    // New date range reducer
    // eventDate: dateSliceReducer,
    cart: persistedCartReducer,
    eventDate: persistedDateReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(eventsApi.middleware, ordersApi.middleware),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(eventsApi.middleware, ordersApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
