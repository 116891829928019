import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/cartSlice";
import { quantityRules } from "../../config/quantityRules";
import "./itemPage.css";
import QuantityExceededModal from "../../components/quantityExceededModal/quantityExceededModal";
import toast from "react-hot-toast";

const ItemPage = () => {
  const { id: itemId } = useParams(); // Get itemID from route params
  const [itemDetails, setItemDetails] = useState({});
  const [quantityToRent, setQuantityToRent] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for quantity exceeded
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const cart = useSelector((state) => state.cart.items); // Access the cart from Redux
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backLink = queryParams.get("from") || "/home";
  const passedDiscountPercentage =
    parseFloat(queryParams.get("discountPercentage")) || 0;
  const passedDiscountQuantity =
    parseInt(queryParams.get("discountQuantity"), 10) || 0;
  // Before using itemCart, check if the item is found in the cart and use .quantity if it exists, otherwise default to 0
  const itemInCart = cart.find(
    (cartItem) => cartItem.id === itemDetails.ItemID
  );
  const cartQuantityDiscount = itemInCart ? itemInCart.quantity : 0;
  const cartQuantityRegular = itemInCart ? itemInCart.regularQuantity : 0;

  //useEffect to fetch the specific item
  useEffect(() => {
    const fetchItemDetails = async () => {
      setLoading(true); // Start loading
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      let url = `${backendUrl}/api/items/${itemId}`;
      // Append date range to URL if available
      if (eventDate) {
        const eventDateString = eventDate.date.split("T")[0]; // Format eventDate as necessary
        url += `?eventDate=${eventDateString}`;
      }
      try {
        const response = await fetch(url);
        const data = await response.json();
        setItemDetails(data);
        // Compare fetched discounts with passed discounts
        const fetchedDiscountPercentage =
          data.DiscountsApplied?.[0]?.discountPercentage || 0;
        const fetchedDiscountQuantity =
          data.DiscountsApplied?.[0]?.quantity || 0;

        if (
          fetchedDiscountPercentage !== passedDiscountPercentage ||
          fetchedDiscountQuantity !== passedDiscountQuantity
        ) {
          toast.error(
            "The discount for this item has changed. Please review the updated details."
          );
        }
      } catch (error) {
        console.error("Failed to fetch item details:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Check if the date range is not set
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
    } else {
      fetchItemDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // Ensure that quantityToRent is a valid number before using it in calculations
  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers or empty input
    if (newValue === "" || /^\d+$/.test(newValue)) {
      setQuantityToRent(newValue);
    }
  };

  // Dispatch set up for redux
  const dispatch = useDispatch();

  const getEntryQuantity = () => {
    return (
      quantityRules.items[itemId] ||
      quantityRules.supercategories[itemDetails.superCategory] ||
      null
    ); // Return null if no specific rule exists
  };

  const discountAvailableQuantity =
    itemDetails.DiscountsApplied?.[0]?.quantity - cartQuantityDiscount || 0;
  const discountPercentage =
    itemDetails.DiscountsApplied?.[0]?.discountPercentage || 0;
  const requestedQuantity = parseInt(quantityToRent, 10) || 0;
  const discountedQuantity = Math.min(
    requestedQuantity,
    discountAvailableQuantity
  );
  const regularQuantity = requestedQuantity - discountedQuantity;

  const totalSavings = itemDetails.RentalPrice
    ? itemDetails.RentalPrice * discountedQuantity * (discountPercentage / 100)
    : 0;

  const finalPrice = itemDetails.RentalPrice
    ? itemDetails.RentalPrice *
        discountedQuantity *
        (1 - discountPercentage / 100) +
      itemDetails.RentalPrice * regularQuantity
    : 0;

  const handleAddToCart = () => {
    if (isNaN(requestedQuantity) || requestedQuantity <= 0) {
      alert("Please enter a valid quantity to rent.");
      return;
    }
    if (
      requestedQuantity + cartQuantityDiscount + cartQuantityRegular >
      itemDetails.AvailableQuantity
    ) {
      setIsModalOpen(true); // Open modal if quantity exceeds available stock
      return;
    }
    const mandatoryQuantity = getEntryQuantity();
    if (mandatoryQuantity && requestedQuantity % mandatoryQuantity !== 0) {
      alert(`Please enter quantities in increments of ${mandatoryQuantity}.`);
      return;
    }

    dispatch(
      addToCart({
        id: itemDetails.ItemID,
        quantity: discountedQuantity,
        itemDetails: {
          ...itemDetails,
          supercategoryId: itemDetails.superCategory,
          catLink: backLink,
          totalSavings,
          finalPrice,
        },
        regularQuantity,
        mandatoryQuantities: mandatoryQuantity,
      })
    );

    setQuantityToRent("");
    toast.success("Item added to cart.");
  };

  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/${itemId}.jpg`;

  return (
    <div className="item-page">
      <QuantityExceededModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        catLink={backLink}
      />
      <div className="item-details">
        {loading ? (
          <p>Loading item details...</p> // Loading message
        ) : (
          <>
            {eventDate.date && (
              <p>
                Selected Event Date:{" "}
                {new Date(eventDate.date).toLocaleDateString()}
              </p>
            )}
            <h2>{itemDetails.ItemDescription}</h2>
            {
              <div>
                Items Added to Cart:{" "}
                {cartQuantityDiscount + cartQuantityRegular}
              </div>
            }
            <p>Rental Price: ${itemDetails.RentalPrice}</p>
            {itemDetails.DiscountsApplied?.length > 0 && (
              <>
                <p>
                  Available for Discount:{" "}
                  {itemDetails.DiscountsApplied[0].quantity -
                    cartQuantityDiscount}
                </p>
                <p>
                  Discount:{" "}
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    {itemDetails.DiscountsApplied[0].discountPercentage}%
                  </span>{" "}
                  off
                </p>
              </>
            )}
            <p className="total-savings-box">
              Total Savings: ${totalSavings.toFixed(2)}
            </p>
            <p>Final Price: ${finalPrice.toFixed(2)}</p>
            {getEntryQuantity() && (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "20px",
                }}
              >
                {" "}
                <p>(Please enter in quantities of {getEntryQuantity()})</p>
              </div>
            )}
            <div>
              <label htmlFor="quantityToRent">Quantity to Rent:</label>
              <input
                type="number"
                id="quantityToRent"
                value={quantityToRent}
                min="0"
                onChange={handleQuantityChange}
              />
            </div>
            <br></br>
            <button onClick={handleAddToCart}>ADD TO ORDER</button> <br></br>
            <br></br> {/*Change the br's later when styling */}
            <button onClick={() => navigate(backLink)}>BACK TO CATEGORY</button>
          </>
        )}
      </div>
      <img
        src={imageUrl}
        alt={itemDetails.ItemDescription}
        className="item-image"
      />
    </div>
  );
};

export default ItemPage;
