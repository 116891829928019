import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

function AdminRequest() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    adminKey: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/request`,
        formData
      );
      toast.success(response.data.message);
      setFormData({
        name: "",
        email: "",
        adminKey: "",
      });
    } catch (error) {
      console.error("Submission failed", error);
      toast.error(
        error.response
          ? error.response.data.message
          : "Error submitting request"
      );
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="adminKey"
        placeholder="Admin Key"
        value={formData.adminKey}
        onChange={handleChange}
        required
      />
      <button type="submit">SUBMIT</button>
    </form>
  );
}

export default AdminRequest;
