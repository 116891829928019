import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { getAuth } from "firebase/auth";

const AcceptAccounts = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingAdmins, setPendingAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchPendingAccounts = async () => {
      setIsLoading(true); // Set loading to true before fetching
      try {
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/pending-users`
        );
        const adminsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/pending-admins`
        );
        setPendingUsers(usersResponse.data.users);
        setPendingAdmins(adminsResponse.data.users);
      } catch (error) {
        toast.error("Failed to fetch pending accounts");
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingAccounts();
  }, []);

  const acceptUser = async (email) => {
    if (window.confirm(`Are you sure you want to accept ${email} as a user?`)) {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/accept-user`,
          { email },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success(`User ${email} accepted`);
        setPendingUsers(pendingUsers.filter((user) => user.email !== email));
      } catch (error) {
        toast.error("Failed to accept user");
      }
    }
  };

  const acceptAdmin = async (email) => {
    if (
      window.confirm(`Are you sure you want to accept ${email} as an admin?`)
    ) {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/accept-admin`,
          { email },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success(`Admin ${email} accepted`);
        setPendingAdmins(
          pendingAdmins.filter((admin) => admin.email !== email)
        );
      } catch (error) {
        toast.error("Failed to accept admin");
      }
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Pending Priorum Staff</h2>
      {pendingAdmins.length > 0 ? (
        <ul>
          {pendingAdmins.map((admin) => (
            <li key={admin.email}>
              {admin.name} ({admin.email}){" "}
              <button onClick={() => acceptAdmin(admin.email)}>Accept</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No pending admins.</p>
      )}
      <h2>Pending Customers</h2>
      {pendingUsers.length > 0 ? (
        <ul>
          {pendingUsers.map((user) => (
            <li key={user.email}>
              {user.name} ({user.email}){" "}
              <button onClick={() => acceptUser(user.email)}>Accept</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No pending users.</p>
      )}
    </div>
  );
};

export default AcceptAccounts;
