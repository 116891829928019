import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./superCategoryList.css";
import DiscountModal from "./discountModal";

const SuperCategoryList = () => {
  const [superCategories, setSuperCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState(null); // To track selected supercategory for adding discount
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();

  useEffect(() => {
    fetchSuperCategories();
  }, []);

  const fetchSuperCategories = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/categories/supercategories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuperCategories(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching supercategories:", error);
    }
  };

  const handleSuperCategoryClick = (superCategory) => {
    const superCategoryUrl = `/categories/${
      superCategory._id
    }?name=${encodeURIComponent(superCategory.name)}`;
    if (superCategory.hasCategories) {
      navigate(superCategoryUrl);
    } else {
      navigate(
        `/items/supercategory/${superCategory._id}?name=${encodeURIComponent(
          superCategory.name
        )}`
      );
    }
  };

  const openDiscountModal = (superCategory) => {
    setSelectedSuperCategory(superCategory);
    setIsModalOpen(true);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="supercategories-container">
      <ul className="supercategories-list">
        {superCategories.map((superCategory) => (
          <li key={superCategory._id} className="supercategory-item">
            <div className="supercategory-item-content">
              <button
                className="supercategory-item-button"
                onClick={() => handleSuperCategoryClick(superCategory)}
              >
                {superCategory.name}
              </button>
              <button
                className="add-discount-button"
                onClick={() => openDiscountModal(superCategory)}
                title="Add Supercategory Discount"
              >
                +
              </button>
            </div>
          </li>
        ))}
      </ul>
      {/* Discount Modal for SuperCategory */}
      {isModalOpen && (
        <DiscountModal
          type="superCategory"
          referenceId={selectedSuperCategory._id}
          name={selectedSuperCategory.name}
          onClose={() => setIsModalOpen(false)}
          onSubmitSuccess={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default SuperCategoryList;
