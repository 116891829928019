import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import toast from "react-hot-toast";
import { getAuth } from "firebase/auth";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const PAGE_SIZE = 5;

const AdminOrders = () => {
  const [orderIndexToShowDetails, setOrderIndexToShowDetails] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("all"); // 'all', 'pending', 'confirmed'
  const [totalOrders, setTotalOrders] = useState(0);
  const [visiblePages, setVisiblePages] = useState([]); //NEW PAGES

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [page, statusFilter]);

  useEffect(() => {
    const startPage = Math.floor((page - 1) / PAGE_SIZE) * PAGE_SIZE + 1;
    const endPage = Math.min(
      startPage + PAGE_SIZE - 1,
      Math.ceil(totalOrders / PAGE_SIZE)
    );
    const newVisiblePages = [];
    for (let i = startPage; i <= endPage; i++) {
      newVisiblePages.push(i);
    }
    setVisiblePages(newVisiblePages); // <-- Calculate visible pages
  }, [page, totalOrders]);

  const fetchOrders = async () => {
    setIsLoading(true);
    const statusQuery = statusFilter !== "all" ? `status=${statusFilter}&` : "";
    const url = `${backendUrl}/api/orders/list?${statusQuery}page=${page}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      setOrders(data.orders);
      setTotalOrders(data.totalOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Failed to fetch orders.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(1); // Reset to first page when filter changes
  };

  const handleDeleteOrder = async (orderId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this order? This action cannot be undone."
      )
    ) {
      try {
        const response = await fetch(`${backendUrl}/api/orders/${orderId}`, {
          method: "DELETE",
        });
        if (!response.ok) {
          throw new Error("Failed to delete order");
        }
        setOrders(orders.filter((order) => order._id !== orderId));
        toast.success("Order deleted successfully");
      } catch (error) {
        console.error("Error deleting order:", error);
        toast.error("Failed to delete order");
      }
    }
  };

  const handleConfirmOrder = async (orderId) => {
    let porOrderId = window.prompt("Please enter the POR Order ID:");

    // Parse as a number and validate
    porOrderId = Number(porOrderId);
    if (!porOrderId) {
      toast.error("A valid POR Order ID is required to confirm the order.");
      return;
    }
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    if (window.confirm("Confirm to mark the order as complete?")) {
      try {
        const response = await fetch(
          `${backendUrl}/api/orders/confirm/${orderId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ porOrderId }),
          }
        );
        if (!response.ok) throw new Error("Failed to confirm order");
        toast.success("Order confirmed successfully.");
        fetchOrders(); // Refetch orders to update the list
      } catch (error) {
        console.error("Error confirming order:", error);
        toast.error("Failed to confirm order.");
      }
    }
  };

  const downloadOrderPDF = (order) => {
    const doc = new jsPDF();
    let y = 10; // Initial vertical offset
    const lineHeight = 7; // Line height for each row

    const checkAndAddNewPage = () => {
      if (y > 270) {
        // Near the bottom of the page
        doc.addPage();
        y = 10; // Reset y to the top of the new page
      }
    };

    // Helper function to add text to the document
    const addText = (text, x, y, isBold = false) => {
      if (isBold) {
        doc.setFont("helvetica", "bold");
      } else {
        doc.setFont("helvetica", "normal");
      }
      doc.text(text, x, y);
      return y + lineHeight; // Increment y position for the next line
    };

    // Title
    doc.setFontSize(16);
    y = addText("ORDER SUMMARY", 10, y, true);

    doc.setFontSize(12);
    // Order Details
    y = addText(`Order ID: ${order._id}`, 10, y);
    y = addText(`Name: ${order.name}`, 10, y);
    y = addText(`Email: ${order.email}`, 10, y);
    y = addText(`Address: ${order.address}`, 10, y);
    y = addText(`Phone: ${order.phone}`, 10, y);
    y = addText(`Date Out: ${order.dateOut.split("T")[0]}`, 10, y);
    y = addText(`Date In: ${order.dateIn.split("T")[0]}`, 10, y);
    y = addText(`Event Date: ${order.eventDate.split("T")[0]}`, 10, y);
    y = addText(`Event Notes: ${order.notes || ""}`, 10, y);
    y += 5; // Additional space before items

    // Items Header
    doc.setFontSize(14);
    y = addText("ITEMS", 10, y, true);

    // Items Details
    doc.setFontSize(12);
    order.items.forEach((item) => {
      checkAndAddNewPage();
      y = addText(`Item ID: ${item.itemID}`, 10, y);
      y = addText(`Name: ${item.description}`, 10, y);
      y = addText(`Quantity: ${item.quantity}`, 10, y);
      y = addText(
        `Additional Non-Discount Qty: ${item.additionalQuantity}`,
        10,
        y
      );
      y = addText(`Price: $${item.price.toFixed(2)}`, 10, y);
      y = addText(`Discount: ${item.discount}%`, 10, y);
      y = addText(`Savings: $${item.savings.toFixed(2)}`, 10, y);
      y += 5; // Extra space after each item
    });

    checkAndAddNewPage(); // Check before adding totals
    y += 5; // Space before totals

    // Totals
    y = addText(
      `Total Rental Price: $${order.totalRentalPrice.toFixed(2)}`,
      10,
      y
    );
    y = addText(`Total Savings: $${order.totalSavings.toFixed(2)}`, 10, y);
    y = addText(`Created At: ${order.createdAt}`, 10, y);
    y = addText(`Updated At: ${order.updatedAt}`, 10, y);
    y = addText(`Status: ${order.status}`, 10, y);

    doc.save(`order-details-${order._id}.pdf`);
  };

  const PaginationControls = () => (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      {page > 1 && <button onClick={() => setPage(1)}>&laquo; First</button>}
      {page > PAGE_SIZE && (
        <button onClick={() => setPage(visiblePages[0] - 1)}>
          Prev &larr;
        </button>
      )}
      {visiblePages.map((p) => (
        <button
          key={p}
          style={p === page ? { color: "white" } : {}}
          onClick={() => setPage(p)}
        >
          {p}
        </button>
      ))}
      {visiblePages[visiblePages.length - 1] * PAGE_SIZE < totalOrders && (
        <button
          onClick={() => setPage(visiblePages[visiblePages.length - 1] + 1)}
        >
          Next &rarr;
        </button>
      )}
      {page * PAGE_SIZE < totalOrders && (
        <button onClick={() => setPage(Math.ceil(totalOrders / PAGE_SIZE))}>
          Last &raquo;
        </button>
      )}
    </div>
  );

  if (isLoading) return <div>Loading...</div>;
  if (!orders.length)
    return (
      <div>
        <select value={statusFilter} onChange={handleStatusChange}>
          <option value="all">All Orders</option>
          <option value="pending">Pending Orders</option>
          <option value="confirmed">Confirmed Orders</option>
        </select>
        <p>No orders found.</p>
      </div>
    );

  return (
    <div>
      <select value={statusFilter} onChange={handleStatusChange}>
        <option value="all">All Orders</option>
        <option value="pending">Pending Orders</option>
        <option value="confirmed">Confirmed Orders</option>
      </select>

      <PaginationControls />

      {orders.map((order, index) => (
        <div
          key={index}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            marginBottom: "20px",
          }}
        >
          <div
            onClick={() =>
              setOrderIndexToShowDetails(
                index === orderIndexToShowDetails ? null : index
              )
            }
            style={{ cursor: "pointer" }}
          >
            {order.status === "confirmed" && (
              <strong>POR OrderID: {order.porOrderId}</strong>
            )}
            <p>Name: {order.name}</p>
            <p>Address: {order.address}</p>
            <p>Email: {order.email}</p>
            <p>Phone: {order.phone}</p>
            <p>Event Date: {order.eventDate.split("T")[0]}</p>
            <p>Date In: {order.dateIn.split("T")[0]}</p>
            <p>Date Out: {order.dateOut.split("T")[0]}</p>
            <p>Total Price: {order.totalRentalPrice.toFixed(2)}</p>
            <p>Total Savings: {order.totalSavings.toFixed(2)}</p>
            <p>Status: {order.status}</p>
            <p>Ordered On: {new Date(order.createdAt).toLocaleString()}</p>
            <strong>Priorum Order #: {order._id}</strong>
          </div>
          {orderIndexToShowDetails === index && (
            <div>
              <h3>ITEMS</h3>
              {order.items.map((item, itemIndex) => (
                <div key={itemIndex}>
                  <p>Item ID: {item.itemID}</p>
                  <p>Description: {item.description}</p>
                  <p>Discounted Quantity: {item.quantity}</p>
                  <p>
                    Additional Quantity:{" "}
                    {parseInt(item.additionalQuantity || 0)}
                  </p>
                  <p>Price: {item.price.toFixed(2)}</p>
                  <p>Discount: {item.discount}%</p>
                  <p>Savings: {item.savings.toFixed(2)}</p>
                  <hr style={{ width: "50%", margin: "auto" }} />{" "}
                  {/* Centered and 50% width */}
                </div>
              ))}
              <button onClick={() => downloadOrderPDF(order)}>
                Download PDF
              </button>
              {order.status === "pending" && (
                <button onClick={() => handleConfirmOrder(order._id)}>
                  Confirm Order
                </button>
              )}
              <button onClick={() => handleDeleteOrder(order._id)}>
                Delete Order
              </button>
            </div>
          )}
        </div>
      ))}
      <PaginationControls />
    </div>
  );
};

export default AdminOrders;
