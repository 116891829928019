import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import axios from "axios";
import toast from "react-hot-toast";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const statusResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/status`,
        { params: { email } }
      );
      if (statusResponse.data.status !== "active") {
        toast.error("Your account is not active. Access is denied.");
        return;
      }

      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Login successful!");
      navigate("/date");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      toast.error("Please enter your email.");
      return;
    }
    try {
      // Attempt to send the password reset email
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
    } catch (error) {
      // Check for the Firebase error related to non-existing user
      if (error.code === "auth/user-not-found") {
        toast.error("No account exists with that email.");
      } else {
        console.error("Reset password error:", error);
        toast.error("Failed to send reset password email.");
      }
    }
  };

  return (
    <div>
      <h2>CUSTOMER LOGIN</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{ padding: "10px", fontSize: "16px", width: "300px" }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={{ padding: "10px", fontSize: "16px", width: "300px" }}
        />
        <button type="submit">LOGIN</button>
      </form>
      <button onClick={handleResetPassword}>Forgot Password?</button>
    </div>
  );
};

export default UserLogin;
