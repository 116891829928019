import React, { useEffect } from "react";
import SuperCategories from "../components/superCategories/superCategory";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const eventDate = useSelector((state) => state.eventDate);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the date range is not set
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
    }
  }, [eventDate, navigate]);
  return (
    <div>
      <SuperCategories></SuperCategories>
    </div>
  );
}
