import React from "react";
import OrderList from "../components/orderList/orderList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function OrderPage() {
  const eventDate = useSelector((state) => state.eventDate);
  const navigate = useNavigate();
  return (
    <div>
      {eventDate.date && (
        <p>
          Selected Event Date: {new Date(eventDate.date).toLocaleDateString()}
        </p>
      )}
      <button onClick={() => navigate("/home")}>ADD MORE ITEMS</button>
      <OrderList></OrderList>
      <h2>
        SPECIAL PRIORUM DISCOUNTED PRODUCT DEPOSIT AND EXTENDED RENTAL FEE
        POLICY
      </h2>
      <p>
        A 50% deposit becomes non-refundable immediately for Priorum discounted
        products. Should you need to cancel an item ordered at a Priorum
        discount, the 50% deposit will be applied to your account for future
        orders. There will be no refunds of your 50% deposit for Priorum
        discounted products once the order is confirmed. Changes made to the
        delivery date or pick-up date, or items returned late, will incur
        extended rental fees at the full listed rate on the Abbey Party Rents
        website, with no Priorum discounting.
      </p>
    </div>
  );
}

export default OrderPage;
