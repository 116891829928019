import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DateSelection from "./pages/dateSelection";
import HomePage from "./pages/homePage";
import ItemPage from "./pages/itemPage/itemPage";
import AdminPage from "./pages/adminPage";
import OrderPage from "./pages/orderPage";
import OrderSummaryPage from "./pages/orderSummaryPage";
import HowItWorks from "./pages/howItWorks/howItWorks";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import ItemListPage from "./pages/itemListPage/ItemListPage";
import Category from "./components/categories/categories";
import { AuthProvider } from "./context/authContext";
import RequestAccess from "./pages/requestAccessPage/requestAccess";
import AuthPage from "./pages/AuthPage/AuthPage";
import FAQ from "./pages/FAQ/faq";
import UserDashboard from "./pages/userDashboardPage/userDashboardPage";
import CategoryList from "./components/admin/categoryList";
import ItemList from "./components/admin/itemList";
import DownForMaintenance from "./pages/downForMaintenance/downForMaintenance";
import { ProtectedRoute } from "./components/protectedRoute";
import { Toaster } from "react-hot-toast";
import Footer from "./components/footer/footer";
import DateChecker from "./context/dateChecker";
import Header from "./components/header";
import CountdownTimer from "./context/countdownTimer";
import CartUpdateModal from "./components/cartUpdatedModal/cartUpdatedModal";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <Toaster />
        <div className="App">
          <Router>
            <AuthProvider>
              <Header></Header>
              <DateChecker />
              <div className="main-content">
                <CartUpdateModal />
                <CountdownTimer />
                <Routes>
                  <Route exact path="/" element={<RequestAccess />} />
                  <Route
                    exact
                    path="/howItWorks"
                    element={
                      <ProtectedRoute>
                        <HowItWorks />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/user-dashboard"
                    element={
                      <ProtectedRoute>
                        <UserDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/faq"
                    element={
                      <ProtectedRoute>
                        <FAQ />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/date"
                    element={
                      <ProtectedRoute>
                        <DateSelection />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route
                    path="/home"
                    element={
                      <ProtectedRoute>
                        <HomePage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/home/:supercategoryId"
                    element={
                      <ProtectedRoute>
                        <Category />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/home/supercategory/:supercategoryId"
                    element={
                      <ProtectedRoute>
                        <ItemListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/home/category/:categoryId"
                    element={
                      <ProtectedRoute>
                        <ItemListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/item/:id"
                    element={
                      <ProtectedRoute>
                        <ItemPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute adminOnly={true}>
                        <AdminPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/cart"
                    element={
                      <ProtectedRoute>
                        <OrderPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/order-summary"
                    element={
                      <ProtectedRoute>
                        <OrderSummaryPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/categories/:superCategoryId"
                    element={<CategoryList />}
                  />
                  <Route
                    path="/items/supercategory/:superCategoryId"
                    element={<ItemList />}
                  />
                  <Route
                    path="/items/category/:categoryId"
                    element={<ItemList />}
                  />
                  <Route
                    path="/downformaintenance"
                    element={<DownForMaintenance />}
                  />
                </Routes>
              </div>
              <Footer></Footer>
            </AuthProvider>
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
