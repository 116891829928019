import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:5001' }),
  endpoints: (builder) => ({
    addEvent: builder.mutation({
      query: (eventDetails) => ({
        url: '/events',
        method: 'POST',
        body: eventDetails,
      }),
    }),
  }),
});

export const { useAddEventMutation } = eventsApi;
