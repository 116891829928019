import React, { useState } from "react";
import UserRequest from "../../components/requestAccess/userRequest";
import AdminRequest from "../../components/requestAccess/adminRequest";
import CheckStatus from "../../components/requestAccess/checkStatus";
import RequestInstructions from "../../components/requestAccess/requestInstructions";
import { NavLink } from "react-router-dom";

function RequestAccess() {
  const [activeTab, setActiveTab] = useState("user");
  return (
    <div>
      <button
        onClick={() => setActiveTab("user")}
        disabled={activeTab === "user"}
        style={{
          color: activeTab === "user" ? "white" : "black",
          cursor: activeTab === "user" ? "default" : "pointer",
          pointerEvents: activeTab === "user" ? "none" : "auto",
        }}
      >
        CUSTOMER
      </button>
      <button
        onClick={() => setActiveTab("admin")}
        disabled={activeTab === "admin"}
        style={{
          color: activeTab === "admin" ? "white" : "black",
          cursor: activeTab === "admin" ? "default" : "pointer",
          pointerEvents: activeTab === "admin" ? "none" : "auto",
        }}
      >
        PRIORUM STAFF
      </button>
      {activeTab === "user" && (
        <>
          <h2 style={{ textAlign: "center", color: "#0056b3" }}>
            CUSTOMER REQUEST
          </h2>
          <UserRequest />
          <h2 style={{ textAlign: "center", color: "#0056b3" }}>
            CHECK STATUS
          </h2>
          <CheckStatus type="user" />
          <p>
            * If status of account is active a link will show up to create an
            account!
          </p>
          <p>
            Already Created Account?{" "}
            <NavLink to="/auth?mode=login">Login</NavLink>
          </p>
        </>
      )}
      {activeTab === "admin" && (
        <>
          <h2 style={{ textAlign: "center", color: "#0056b3" }}>
            PRIORUM STAFF REQUEST
          </h2>
          <AdminRequest />
          <h2 style={{ textAlign: "center", color: "#0056b3" }}>
            CHECK STATUS
          </h2>
          <CheckStatus type="admin" />
          <p>
            * If status of account is active a link will show up to create an
            account!
          </p>
          <p>
            Already Created Account?{" "}
            <NavLink to="/auth?mode=login">Login</NavLink>
          </p>
        </>
      )}
      <RequestInstructions />
    </div>
  );
}

export default RequestAccess;
