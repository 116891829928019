import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import toast from "react-hot-toast";

const AdminSignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminKey, setAdminKey] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const statusResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/status`,
        { params: { email } }
      );
      if (statusResponse.data.status !== "active") {
        toast.error("Your account is not active.");
        return;
      }

      const keyResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/verifyAdminKey`,
        { adminKey }
      );
      if (keyResponse.status !== 200) {
        toast.error("Invalid Admin Key.");
        return;
      }

      await createUserWithEmailAndPassword(auth, email, password);
      toast.success("Sign up successful!");
      navigate("/admin");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to sign up.");
    }
  };

  return (
    <div>
      <h2>PRIORUM STAFF SIGN UP</h2>
      <form onSubmit={handleSignUp}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{ padding: "10px", fontSize: "16px", width: "300px" }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={{ padding: "10px", fontSize: "16px", width: "300px" }}
        />
        <input
          type="text"
          placeholder="Admin Key"
          value={adminKey}
          onChange={(e) => setAdminKey(e.target.value)}
          required
          style={{ padding: "10px", fontSize: "16px", width: "300px" }}
        />
        <button type="submit">SIGN UP</button>
      </form>
    </div>
  );
};

export default AdminSignUpPage;
