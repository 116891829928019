import React from "react";
import { IconSettings } from "@tabler/icons-react";

function DownForMaintenance() {
  return (
    <div>
      <h1>DOWN FOR MAINTENANCE</h1>
      <p>Platform is currently down for maintenance. We will be back soon! </p>
      <IconSettings size={75}></IconSettings>
    </div>
  );
}

export default DownForMaintenance;
