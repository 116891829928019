import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./itemList.css";
import DiscountModal from "./discountModal";

const ItemList = () => {
  const { categoryId, superCategoryId } = useParams();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedSeasonalDiscounts, setSelectedSeasonalDiscounts] = useState(
    {}
  );

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, [categoryId, superCategoryId]);

  const fetchItems = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      let url = `${process.env.REACT_APP_BACKEND_URL}/api/items/discounts?`;

      if (categoryId) {
        url += `categoryId=${categoryId}`;
      } else if (superCategoryId) {
        url += `superCategoryId=${superCategoryId}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setItems(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleDeleteDiscount = async (discountId) => {
    if (window.confirm("Are you sure you want to delete this discount?")) {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/discounts/${discountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("Discount deleted successfully.");
        setIsLoading(true);
        fetchItems(); // Refresh items after deleting discount
      } catch (error) {
        console.error("Error deleting discount:", error);
      }
    }
  };

  const handleDeleteTier = async (discountId, tierIndex) => {
    if (
      window.confirm(
        "Are you sure you want to delete this discount tier? This action cannot be undone."
      )
    ) {
      try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/discounts/${discountId}?tierIndex=${tierIndex}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("Tier deleted successfully.");
        setIsLoading(true);
        fetchItems(); // Refresh items after deleting tier
      } catch (error) {
        console.error("Error deleting tier:", error);
      }
    }
  };

  const openDiscountModal = (item, discount = null) => {
    setSelectedItem(item);
    setSelectedDiscount(discount); // Set the selected discount, if available
    setIsModalOpen(true);
  };

  const closeDiscountModal = () => {
    setIsModalOpen(false);
    setSelectedDiscount(null); // Reset selected discount when modal closes
  };

  const toggleSeasonalDiscount = (itemID, discountID) => {
    setSelectedSeasonalDiscounts((prev) => ({
      ...prev,
      [itemID]: discountID,
    }));
  };

  const handleDiscountSubmitSuccess = () => {
    setIsLoading(true);
    fetchItems(); // Only refetch after successful discount submission
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="items-list-discount-container">
      <button onClick={() => navigate("/admin")}>BACK TO ADMIN</button>
      {name && <h1>{decodeURIComponent(name)}</h1>}
      <ul className="items-list-discount">
        {items.map((item) => {
          const seasonalDiscounts = item.seasonalDiscounts || [];
          const selectedSeasonalDiscountID =
            selectedSeasonalDiscounts[item.ItemID];
          const applicableDiscount =
            seasonalDiscounts.find(
              (d) => d._id === selectedSeasonalDiscountID
            ) || item.applicableDiscount;

          return (
            <li key={item.ItemID} className="item-discount">
              <strong>
                {item.ItemID} - {item.ItemDescription}
              </strong>
              <p>Quantity Owned: {item.QuantityOwned}</p>
              <p>Discount Type: {applicableDiscount?.type || "No Discount"}</p>
              {applicableDiscount.noDiscount && <p>No Discount</p>}
              <ul>
                {applicableDiscount?.tiers.map((tier, index) => (
                  <li key={index}>
                    {tier.percentageOfAvailability}% at{" "}
                    {tier.discountPercentage}% discount
                    <button
                      className="item-discount-button"
                      onClick={() =>
                        handleDeleteTier(applicableDiscount._id, index)
                      }
                    >
                      DELETE TIER
                    </button>
                  </li>
                ))}
              </ul>
              <button
                className="item-discount-button"
                onClick={() => handleDeleteDiscount(applicableDiscount._id)}
              >
                DELETE DISCOUNT
              </button>
              <button
                className="add-discount-button"
                onClick={() =>
                  openDiscountModal(
                    item,
                    seasonalDiscounts.find(
                      (d) => d._id === selectedSeasonalDiscountID
                    ) // Pass selected seasonal discount if one is chosen
                  )
                }
                title="Add Item Discount"
              >
                +
              </button>
              {seasonalDiscounts.length > 0 && (
                <div className="seasonal-discount-toggle">
                  <label>Select Seasonal Discount:</label>
                  <select
                    value={selectedSeasonalDiscountID || ""}
                    onChange={(e) =>
                      toggleSeasonalDiscount(item.ItemID, e.target.value)
                    }
                  >
                    <option value="">None</option>
                    {seasonalDiscounts.map((discount) => (
                      <option key={discount._id} value={discount._id}>
                        {new Date(discount.startDate)
                          .toISOString()
                          .slice(0, 10)}{" "}
                        -{" "}
                        {new Date(discount.endDate).toISOString().slice(0, 10)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {/* Discount Modal for Item */}
      {isModalOpen && (
        <DiscountModal
          type="item"
          referenceId={selectedItem.ItemID}
          name={selectedItem.ItemDescription}
          onClose={closeDiscountModal}
          onSubmitSuccess={handleDiscountSubmitSuccess}
          selectedDiscount={selectedDiscount} // Pass selected seasonal discount if one is selected
        />
      )}
    </div>
  );
};

export default ItemList;
