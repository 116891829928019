import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import "./userOrders.css";

function UserOrders() {
  const { currentUser, userDetails } = useAuth();
  const [orders, setOrders] = useState([]);
  const [confirmedIndexToShowDetails, setConfirmedIndexToShowDetails] =
    useState(null);
  const [pendingIndexToShowDetails, setPendingIndexToShowDetails] =
    useState(null);
  const [totalSavings, setTotalSavings] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      if (userDetails._id && currentUser) {
        setIsLoading(true);
        try {
          const token = await currentUser.getIdToken();
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/orders/user-orders/${userDetails._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Use the refreshed token
              },
            }
          );
          setOrders(response.data.orders);
          setTotalSavings(response.data.totalSavings);
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to fetch orders:", error);
          setError("Failed to fetch orders");
          setIsLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser, userDetails._id]);

  const toggleConfirmedDetails = (index) => {
    console.log("Toggling confirmed details for index:", index);
    setConfirmedIndexToShowDetails((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const togglePendingDetails = (index) => {
    console.log("Toggling pending details for index:", index);
    setPendingIndexToShowDetails((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const renderOrderDetails = (order, index, isConfirmed) => (
    <div
      key={order._id}
      style={{
        padding: "10px",
        border: "1px solid #ccc",
        marginBottom: "20px",
      }}
    >
      <div
        onClick={() =>
          isConfirmed
            ? toggleConfirmedDetails(index)
            : togglePendingDetails(index)
        }
        style={{ cursor: "pointer" }}
      >
        <p>Event Date: {order.eventDate.split("T")[0]}</p>
        <p style={{ fontWeight: "bold" }}>
          Total Savings: ${order.totalSavings.toFixed(2)}
        </p>
        <p>Total Price: ${order.totalRentalPrice.toFixed(2)}</p>
        <p>
          Status: {order.status === "pending" ? "Pending ⏳" : "Confirmed ✅"}
        </p>
      </div>
      {(isConfirmed
        ? confirmedIndexToShowDetails === index
        : pendingIndexToShowDetails === index) && (
        <div>
          <h3>ITEMS</h3>
          {order.items.map((item, itemIndex) => (
            <div key={itemIndex}>
              <p>Description: {item.description}</p>
              <p>Quantity: {item.quantity}</p>
              <p>Price: {item.price.toFixed(2)}</p>
              <p>Discount: {item.discount}%</p>
              <p>Savings: {item.savings.toFixed(2)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="orders-layout">
      <div className="orders-section confirmed-orders">
        <h2>CONFIRMED ORDERS</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : orders.filter((order) => order.status === "confirmed").length >
          0 ? (
          orders
            .filter((order) => order.status === "confirmed")
            .map(
              (order, index) => renderOrderDetails(order, index, true) // Pass true for confirmed orders
            )
        ) : (
          <p>No confirmed orders.</p>
        )}
        <h3 className="total-savings">
          TOTAL SAVINGS: ${totalSavings.toFixed(2)}
        </h3>
      </div>

      <div className="orders-section pending-orders">
        <h2>PENDING ORDERS</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : orders.filter((order) => order.status === "pending").length > 0 ? (
          orders
            .filter((order) => order.status === "pending")
            .map(
              (order, index) => renderOrderDetails(order, index, false) // Pass false for pending orders
            )
        ) : (
          <p>No pending orders.</p>
        )}
      </div>
    </div>
  );
}

export default UserOrders;
