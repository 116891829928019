import React from "react";
import Calender from "../components/calender";

export default function DateSelection() {
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.calender}>
          <Calender />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Ensure the container takes at least the full height of the viewport
  },
  calender: {
    display: "flex",
    justifyContent: "center", // Center horizontally
    flexGrow: 1, // Allow this container to grow and take up available space
    padding: "20px", // Optional, for some spacing around the calendar
  },
};
