import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCartData } from "../store/cartSlice";

const CountdownTimer = () => {
  const dispatch = useDispatch();

  // Retrieve the saved time left from localStorage or set it to 1 hour
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("cartTimer");
    return savedTime ? parseInt(savedTime, 10) : 3600; // Default to 1 hour
  });

  useEffect(() => {
    // Save the timer to localStorage every second
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = prev > 0 ? prev - 1 : 0;
        localStorage.setItem("cartTimer", newTime); // Save to localStorage
        return newTime;
      });
    }, 1000);

    // Trigger cart update and reset the timer
    if (timeLeft === 0) {
      dispatch(updateCartData());
      setTimeLeft(3600); // Reset timer to 1 hour
      localStorage.setItem("cartTimer", 3600); // Reset in localStorage
    }

    return () => clearInterval(interval);
  }, [timeLeft, dispatch]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="countdown-timer">
      {timeLeft <= 900 ? (
        <p>Cart will refresh in: {formatTime(timeLeft)}</p>
      ) : null}
    </div>
  );
};

export default CountdownTimer;
