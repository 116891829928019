import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import {
  differenceInCalendarDays,
  format,
  startOfMonth,
  isBefore,
  addDays,
} from "date-fns";
import "react-day-picker/dist/style.css";
import { setDate } from "../store/dateSlice";
import { clearCart } from "../store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import "./calender.css";

export default function Calender() {
  //state for date
  const [selectedDate, setSelectedDate] = useState();

  const eventDate = useSelector((state) => state.eventDate);
  const cart = useSelector((state) => state.cart.items);

  const today = new Date();
  const initialDate = addDays(today, 30);
  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/CalendarPage.jpg`;

  useEffect(() => {
    // If there's an existing date in redux and it's truthy, use it
    if (eventDate && eventDate.date) {
      setSelectedDate(new Date(eventDate.date));
    } else {
      //Leave selectedDate as undefined
      setSelectedDate(undefined); // or setSelectedDate(null);
    }
  }, [eventDate]);

  const navigate = useNavigate();

  //state for months to avoid navigation to old months
  const [displayedMonth, setDisplayedMonth] = useState(initialDate);

  //dispatch to add date to redux
  const dispatch = useDispatch();

  const handleOk = () => {
    if (selectedDate) {
      if (eventDate.date === selectedDate.toISOString()) {
        // If the selected date is the same as the current event date, do not clear the cart
        dispatch(setDate(selectedDate.toISOString())); // Dispatch single event date
        navigate("/home");
      } else {
        if (cart.length > 0) {
          const confirmation = window.confirm(
            "Please complete check-out process before selecting a new date, which will CLEAR YOUR CART. Are you sure you want to continue?"
          );
          if (!confirmation) {
            setSelectedDate(new Date(eventDate.date));
            return;
          } // If user cancels, date is the same
        }
        // Dispatch the date range in the expected format
        dispatch(setDate(selectedDate.toISOString())); // Dispatch single event date
        dispatch(clearCart()); // Clear Cart
        navigate("/home");
      }
    } else {
      console.log("Event date not selected.");
    }
  };

  function isUnavailableDate(date) {
    const today = new Date();
    return (
      differenceInCalendarDays(date, today) < 0 ||
      differenceInCalendarDays(addDays(today, 30), date) > 0
    );
  }

  //to prevent changing to past month
  function handleMonthChange(month) {
    if (isBefore(startOfMonth(month), startOfMonth(new Date()))) {
      return;
    }
    setDisplayedMonth(month);
  }

  // Footer content for single date selection
  let footer = "Please pick an event date.";
  if (selectedDate) {
    footer = <p>Selected date: {format(selectedDate, "PPP")}</p>;
  }

  return (
    <div className="calendar-container">
      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={setSelectedDate}
        footer={footer}
        showOutsideDays
        disabled={isUnavailableDate}
        month={displayedMonth}
        onMonthChange={handleMonthChange}
        style={{
          border: "2px solid #ccc",
          borderRadius: "8px",
          fontSize: "20px",
          textAlign: "center",
          paddingLeft: "40px",
          padding: "40px",
        }}
      />
      <p>*Events can only be booked 30 days or more in advance</p>
      <div className="button-container">
        <button onClick={handleOk} disabled={!selectedDate}>
          {selectedDate ? "CONTINUE TO RENTALS" : "OK"}
        </button>
      </div>
      <img src={imageUrl} alt="Calendar" className="calendar-image" />
    </div>
  );
}
