import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  hideCartUpdateModal,
  updateModalIssues,
} from "../../store/cartSlice";
import "./cartUpdatedModal.css";
const CartUpdateModal = () => {
  const dispatch = useDispatch();
  const { itemsWithIssues, itemsWithDiscountChanges, isOpen } = useSelector(
    (state) => state.cart.modalData
  );

  if (!isOpen) return null;

  const handleRemove = (id) => {
    dispatch(removeFromCart(id));
    dispatch(updateModalIssues(id));
  };

  const handleCloseModal = () => {
    dispatch(hideCartUpdateModal());
  };

  return (
    <div className="cart-update-modal">
      <div className="cart-update-modal-content">
        <h2>Cart Update Required</h2>
        {itemsWithIssues.length > 0 && (
          <div className="issues-section">
            <h3>Items with Insufficient Quantity:</h3>
            <ul>
              {itemsWithIssues.map((item) => (
                <li key={item.id}>
                  {item.itemDetails.ItemDescription} has insufficient quantity.
                  <button
                    className="remove-item-btn"
                    onClick={() => handleRemove(item.id)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {itemsWithDiscountChanges.length > 0 && (
          <div className="discount-section">
            <h3>Items with Discount Changes:</h3>
            <ul>
              {itemsWithDiscountChanges.map((item) => (
                <li key={item.id}>
                  {item.itemDetails.ItemDescription}, Discount now:{" "}
                  {item.updatedDiscountPercentage}%
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="modal-footer">
          <button
            className="close-modal-btn"
            onClick={handleCloseModal}
            disabled={itemsWithIssues.length > 0}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartUpdateModal;
