//LogList.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";

const PAGE_SIZE = 5;

const LogList = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(0);
  const [visiblePages, setVisiblePages] = useState([]); // Visible page numbers
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const startPage = Math.floor((page - 1) / PAGE_SIZE) * PAGE_SIZE + 1;
    const endPage = Math.min(
      startPage + PAGE_SIZE - 1,
      Math.ceil(totalLogs / PAGE_SIZE)
    );
    const newVisiblePages = [];
    for (let i = startPage; i <= endPage; i++) {
      newVisiblePages.push(i);
    }
    setVisiblePages(newVisiblePages); // Update visible pages based on the current page
  }, [page, totalLogs]);

  const fetchLogs = async () => {
    setIsLoading(true); // Set loading to true before fetching logs
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/logs?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLogs(response.data.logs); // Update the logs with the current page of logs
      setTotalLogs(response.data.totalLogs); // Update the total number of logs
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const PaginationControls = () => (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      {page > 1 && <button onClick={() => setPage(1)}>&laquo; First</button>}
      {page > PAGE_SIZE && (
        <button onClick={() => setPage(visiblePages[0] - 1)}>
          Prev &larr;
        </button>
      )}
      {visiblePages.map((p) => (
        <button
          key={p}
          style={p === page ? { color: "white" } : {}}
          onClick={() => setPage(p)}
        >
          {p}
        </button>
      ))}
      {visiblePages[visiblePages.length - 1] * PAGE_SIZE < totalLogs && (
        <button
          onClick={() => setPage(visiblePages[visiblePages.length - 1] + 1)}
        >
          Next &rarr;
        </button>
      )}
      {page * PAGE_SIZE < totalLogs && (
        <button onClick={() => setPage(Math.ceil(totalLogs / PAGE_SIZE))}>
          Last &raquo;
        </button>
      )}
    </div>
  );

  if (isLoading) return <div>Loading...</div>;
  if (!logs.length) return <p>No logs found.</p>;

  return (
    <div>
      {logs.map((log, index) => (
        <div
          key={index}
          style={{
            marginBottom: "10px",
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
          }}
        >
          <p>
            <strong>Timestamp:</strong>{" "}
            {new Date(log.timestamp).toLocaleString()}
          </p>
          <p>
            <strong>User:</strong> {log.user}
          </p>
          <p>
            <strong>Action:</strong> {log.action}
          </p>
          <p>
            <strong>Details:</strong> {log.details}
          </p>
        </div>
      ))}
      <PaginationControls />
    </div>
  );
};

export default LogList;
