import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./categoryList.css";
import DiscountModal from "./discountModal";

const CategoryList = () => {
  const { superCategoryId } = useParams();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null); // To track selected category for adding discount
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the supercategory name from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const superCategoryName = queryParams.get("name");

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, [superCategoryId]);

  const fetchCategories = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/categories/supercategories/${superCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(
      `/items/category/${categoryId}?name=${encodeURIComponent(categoryName)}`
    );
  };

  const openDiscountModal = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="categories-container">
      <h1>Categories in {superCategoryName}</h1>
      <ul className="categories-list">
        {categories.map((category) => (
          <li key={category._id} className="category-item">
            <div className="category-item-content">
              <button
                className="category-item-button"
                onClick={() => handleCategoryClick(category._id, category.name)}
              >
                {category.name}
              </button>
              <button
                className="add-discount-button"
                onClick={() => openDiscountModal(category)}
                title="Add Category Discount"
              >
                +
              </button>
            </div>
          </li>
        ))}
      </ul>
      {/* Discount Modal for Category */}
      {isModalOpen && (
        <DiscountModal
          type="category"
          referenceId={selectedCategory._id}
          name={selectedCategory.name}
          onClose={() => setIsModalOpen(false)}
          onSubmitSuccess={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default CategoryList;
